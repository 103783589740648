import React from "react";
import "./Toolbar.css";
import { Button, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Typography, Menu } from "@mui/material";
import { ContentCut, Menu as MenuIcon } from "@mui/icons-material";

export default function Toolbar(props) {
  const { title, leftItems, rightItems } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const showMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Paper elevation={0} style={{margin: "0 2px 0 2px", borderRadius: "5px"}}>
      {
        (props.isMobile && props.isMobile()) && <div className="toolbar">
          <Button startIcon={<MenuIcon />} onClick={showMenu}>
          </Button>
          <h1 className="toolbar-title">{title}</h1>
          <div onClick={props.onClickRightItem} className="right-items">
            {rightItems[0]}
          </div>
        </div>
      }
      {
        (!props.isMobile || (props.isMobile && props.isMobile() !== true)) && 
        <div className="toolbar">
          <div onClick={props.onClickLeftItem} className="left-items">
            {leftItems}
          </div>
          {/* <div className="left-items">
            <Link to="/about">About</Link>
          </div> */}
          <h1 className="toolbar-title">{title}</h1>
          <div onClick={props.onClickRightItem} className="right-items">
            {rightItems}
          </div>
        </div>
      }
      
      {/* {props.children} */}

      <Menu 
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuList>
          <MenuItem>
            {rightItems}&nbsp;&nbsp;
            {leftItems}
              {/* <ListItemIcon>
                <ContentCut fontSize="small" />
              </ListItemIcon>
              <ListItemText>Cut</ListItemText>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                ⌘X
              </Typography> */}
            </MenuItem>
          {/* {
            leftItems?.map((item, index) => {
              return <MenuItem>
                {item}
              </MenuItem>
            })
          }
          {
            rightItems?.map((item, index) => {
              return <MenuItem>
                {item}
              </MenuItem>
            })
          } */}
          
        </MenuList>
      </Menu>
      
    </Paper>
    
  );
}
