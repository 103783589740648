import React, { Component } from 'react';
import { List, ListItem, ListItemText, Paper, Button, Divider, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, IconButton, ListItemAvatar, TextField, Box } from '@mui/material';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import Api from '../../customlib/Api';
import axios from 'axios';
import Global from '../../customlib/Global';
import ReactLoading from 'react-loading';

class EditAppProfile extends Component {
    state = { 
        listUsers: [],
        countLavengerUser: 0,
        countNotLavengerUser: 0,
        isSaving: false,
        message: "Klik User Lavender agar menjadi User Lotus",
        isError: false,
        appProfile: {
            appProfileId: "",
            waNumber: "",
            appType: "",
            appLabel: "",
            appEnabled: false,
            appVendor: "",
            appKey: "",
            wabaId: "" 
        },
        listUserLavender: [],
        listUserLotus: [],
        keywordLavenderUser: "",
        keywordLotusUser: ""
    }

    getLavengerUsers = () => {

        const params = {
            appProfileId: this.state.appProfile.appProfileId
        };

        axios
        .post(Api.lavengerUsersList(), params, Api.getRequestHeader())
        .then((response) => {

            console.log("LAVENGER USERS : ", response.data);

            var users = response.data.data;
            var lotusUsers = users.filter(user => user.isLavengerUser === true);
            var lavenderUsers = users.filter(user => user.isLavengerUser === false);
            var countLavengerUser = lotusUsers.length;
            var countNotLavengerUser = lavenderUsers.length;

            console.log("LAVENGERUSERS : ", users);
            this.setState({listUsers: users, listUserLavender: lavenderUsers, listUserLotus: lotusUsers, countLavengerUser: countLavengerUser, countNotLavengerUser: countNotLavengerUser});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    filterUserLavender = (keyword) => {
        return this.state.listUsers.filter(user => user.isLavengerUser === false && 
            (
            user.email.toLowerCase().includes(`${keyword}`.toLowerCase()) || 
            user.name.toLowerCase().includes(`${keyword}`.toLowerCase())
            )
        );
    }

    filterUserLotus = (keyword) => {
        return this.state.listUsers.filter(user => user.isLavengerUser === true && 
            (
            user.email.toLowerCase().includes(`${keyword}`.toLowerCase()) || 
            user.name.toLowerCase().includes(`${keyword}`.toLowerCase())
            )
        );
    }

    saveLavengerUsers = () => {

        console.log(this.state.appProfile);

        this.setState({isSaving: true},() => {
            let message = "";

            const newChatUsers = this.state.listUsers.filter(user => user.isLavengerUser === true);

            var newChatUserIds = [];
            newChatUsers.forEach(user => {
                newChatUserIds.push(user.id);
            });

            const params = {...this.state.appProfile}
            params.ids = newChatUserIds;
            params.updatedBy = Global.get().user.id;
            params.updatedByName = Global.get().user.name;

            axios
            .post(Api.saveAppProfile(), params, Api.getRequestHeader())
            .then((response) => {

                message = response.data.message;

                console.log("RESPONSE : ", response.data);
                this.setState({isSaving: false, message: message, isError: !response.data.success});
            })
            .catch((error) => {
                this.setState({isSaving: false, message: message, isError: true});
                console.error("There was an error!", error);
            });
        });

        return;
        this.setState({isSaving: true}, () => {

            const newChatUsers = this.state.listUsers.filter(user => user.isLavengerUser === true);

            var newChatUserIds = [];
            newChatUsers.forEach(user => {
                newChatUserIds.push(user.id);
            });

            const params = {
                "ids": newChatUserIds,
                "updatedBy": Global.get().user.id,
                "updatedByName": Global.get().user.name
            };

            console.log("PARAMS : ", params);

            let message = "";

            axios
            .post(Api.saveChatUserIds(), params, Api.getRequestHeader())
            .then((response) => {

                message = response.data.message;


                console.log("RESPONSE : ", response.data);
                this.setState({isSaving: false, message: message, isError: !response.data.success});
            })
            .catch((error) => {
                this.setState({isSaving: false, message: message, isError: true});
                console.error("There was an error!", error);
            });

        });
    };

    handleMoveUser = (userData) => {
        const users = [...this.state.listUsers];
        var findUser = users.filter(user => user.id === userData.id);
        

        try{
            if(findUser.length > 0){
                findUser[0].isLavengerUser = !findUser[0].isLavengerUser;
            }

            var countLavengerUser = users.filter(user => user.isLavengerUser === true).length;
            var countNotLavengerUser = users.filter(user => user.isLavengerUser === false).length;
            this.setState(
                {
                    listUsers: users, 
                    countLavengerUser: countLavengerUser, 
                    countNotLavengerUser: countNotLavengerUser,
                    message: "Perubahan belum disimpan, klik tombol simpan"
                }
            );

        }catch(err){
            console.log(err);
        }


    }

    handleAppNameChanged = (event) => {
        const appProfile = {...this.state.appProfile}
        appProfile.appLabel = event.target.value;
        this.setState({appProfile: appProfile});
    }

    handleAppWaNumberChanged = (event) => {
        const appProfile = {...this.state.appProfile}
        appProfile.waNumber = event.target.value;
        this.setState({appProfile: appProfile});
    }

    handleAppKeyChanged = (event) => {
        const appProfile = {...this.state.appProfile}
        appProfile.appKey = event.target.value;
        this.setState({appProfile: appProfile});
    }

    handleWabaIdChanged = (event) => {
        const appProfile = {...this.state.appProfile}
        appProfile.wabaId = event.target.value;
        this.setState({appProfile: appProfile});
    }
    handleKeywordLavenderChanged = (event) => {
        this.setState({keywordLavenderUser: event.target.value});
    }
    handleKeywordLotusChanged = (event) => {
        this.setState({keywordLotusUser: event.target.value});
    }

    handleSwitchLeads = () => {
        let currentAppEnabledState = this.state.appProfile.appEnabled;
        let appProfile = {...this.state.appProfile}
        appProfile.appEnabled = !currentAppEnabledState;
        this.setState({appProfile: appProfile});
    }

    handleAppTypeChanged = (event) => {
        const appProfile = {...this.state.appProfile}
        appProfile.appType = event.target.value;
        this.setState({appProfile: appProfile});
    }
    
    handleAppVendorChanged = (event) => {
        const appProfile = {...this.state.appProfile}
        appProfile.appVendor = event.target.value;
        this.setState({appProfile: appProfile});
    }

    componentDidMount() {
        console.log("PROPS : ",this.props.data);
        let appProfile = {
            appProfileId: this.props.data._id,
            waNumber: this.props.data.waNumber,
            appType: this.props.data.type,
            appLabel: this.props.data.label,
            appEnabled: this.props.data.enabled,
            appVendor: this.props.data.appVendor, 
            appKey: this.props.data.appKey,
            wabaId: this.props.data.wabaId 
        }
        this.setState({
            appProfile: appProfile
        }, () => {
            console.log("DATA : ", this.state.appProfile);
            this.getLavengerUsers();
        });
        
    }

    render() { 
        return ( 
            <Paper>
                <div className="container">
                    <div className="row">
                        <div className="col-9">

                            {
                                this.state.isSaving ? 
                                (
                                    <><ReactLoading type="bubbles" width="50" height="20" /> Sedang menyimpan ....</>
                                ) : 
                                (
                                    <div className={this.state.isError ? "alert alert-danger" : "alert alert-primary"}>
                                        {this.state.message}
                                    </div>
                                )
                            }

                        </div>
                        <div className="col-2">
                            <div className="btn btn-primary btn-block" onClick={this.saveLavengerUsers}>
                                Simpan
                            </div>
                        </div>
                        <div className="col-1">
                                <div className="btn btn-danger" onClick={this.props.onClose}>X</div>
                            </div>
                    </div>
                    <Divider style={{marginTop: 10, marginBottom: 10}} />

                    <Paper>
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        {/* <label>App Name</label>
                                        <input
                                            onChange={this.handleAppNameChanged}
                                            value={this.state.appProfile.appLabel}
                                            type="name"
                                            className="form-control"
                                            id="appName"
                                            aria-describedby="emailHelp"
                                        /> */}
                                        <TextField 
                                            id="txt-app-name" 
                                            label="App Name" 
                                            variant="outlined" 
                                            type='text'
                                            onChange={this.handleAppNameChanged}
                                            value={this.state.appProfile.appLabel}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div className="form-group">
                                        {/* <label>WhatsApp Number</label>
                                        <input
                                            onChange={this.handleAppWaNumberChanged}
                                            value={this.state.appProfile.waNumber}
                                            type="email"
                                            className="form-control"
                                            id="waNumber"
                                            aria-describedby="emailHelp"
                                            onKeyDown={this.onKeyDownHandler}
                                        /> */}
                                        <TextField 
                                            id="txt-wa-number" 
                                            label="WhatsApp Number" 
                                            variant="outlined" 
                                            type='tel'
                                            onChange={this.handleAppWaNumberChanged}
                                            value={this.state.appProfile.waNumber}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <FormControl size='small' variant="outlined" style={{width: '100%', marginTop: 0}}>
                                        <InputLabel id="demo-simple-select-outlined-label">Vendor Aplikasi</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.appProfile.appVendor}
                                            onChange={this.handleAppVendorChanged}
                                            label="Tipe Aplikasi"
                                        >
                                            <MenuItem key="vendorInfobip" value="INFOBIP">Infobip</MenuItem>
                                            <MenuItem key="vendorVonage" value="VONAGE">Vonage</MenuItem>
                                            <MenuItem key="vendorVonage" value="QONTAK">Qontak</MenuItem>
                                            <MenuItem key="vendorVonage" value="BION">Bion</MenuItem>
                                            <MenuItem key="vendorVonage" value="BION CRM">Bion CRM</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Box>
                                        <FormGroup variant="outlined" style={{marginTop: 7}}>
                                            <FormControlLabel
                                                label= {this.state.appProfile.appEnabled === true ? "App is (ON)" : "App is (OFF)"}
                                                control={
                                                    <Switch
                                                        checked={this.state.appProfile.appEnabled === true}
                                                        onChange={this.handleSwitchLeads}
                                                        name="switchLeads"
                                                        // color="primary"
                                                    />
                                                }
                                                
                                            />
                                        </FormGroup> 
                                    </Box>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <FormControl size='small' variant="outlined" style={{width: '100%', marginTop: 0}}>
                                            <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={this.state.appProfile.appType}
                                                onChange={this.handleAppTypeChanged}
                                                label="Tipe Aplikasi"
                                            >
                                                <MenuItem key="tipeSales" value="SALES-APP">Aplikasi Sales</MenuItem>
                                                <MenuItem key="tipePurchasing" value="PURCHASING-APP">Aplikasi Purchasing</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="form-group">
                                        {/* <label>App Key</label>
                                        <input
                                            onChange={this.handleAppKeyChanged}
                                            value={this.state.appProfile.appKey}
                                            type="text"
                                            className="form-control"
                                            id="appKey"
                                            aria-describedby="appKey"
                                            onKeyDown={this.onKeyDownHandler}
                                        /> */}
                                        <TextField 
                                            id="txt-app-key" 
                                            label="App Key" 
                                            variant="outlined" 
                                            type='text'
                                            onChange={this.handleAppKeyChanged}
                                            value={this.state.appProfile.appKey}
                                            fullWidth
                                            size='small'
                                            multiline
                                            maxRows={3}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField 
                                            id="txt-wabaid" 
                                            label="Waba ID" 
                                            variant="outlined" 
                                            type='text'
                                            onChange={this.handleWabaIdChanged}
                                            value={this.state.appProfile.wabaId}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </Paper>
                    <br />
                    <div className="row">
                        <div className="col-6">
                            <Paper elevation={3} style={{width: "100%", padding: 10}}>
                                <div className="row">
                                    <div className="col-9">
                                        <h4>User Lavender</h4>
                                    </div>
                                    <div className="col-2 text-right">
                                        <div style={{marginBottom: 10}} className="badge badge-primary">{this.state.countNotLavengerUser} User</div>
                                    </div>
                                </div>
                                {/* <Divider sx={{marginTop: "5px", marginBottom: "5px"}} /> */}
                                <TextField 
                                    id="txt-keyword-lavender" 
                                    label="pencarian user Lavender" 
                                    variant="outlined" 
                                    type='text'
                                    onChange={this.handleKeywordLavenderChanged}
                                    value={this.state.keywordLavenderUser}
                                    fullWidth
                                    size='small'
                                />
                                <List style={{maxHeight: 470, overflowY: "scroll", marginTop: "10px"}}>
                                    {
                                    // this.state.listUsers.filter(user => user.isLavengerUser === false).map(user => 
                                    this.filterUserLavender(this.state.keywordLavenderUser).map(user => 
                                        (
                                            <ListItem sx={{cursor: "pointer"}} dense key={user.id} onClick={() => {this.handleMoveUser(user)}}
                                                secondaryAction={
                                                    // <IconButton edge="end" aria-label="delete">
                                                        <ChevronRight style={{marginTop: 5}} />
                                                    // </IconButton>
                                                }
                                                >
                                                <ListItemText
                                                    primary={user.name}
                                                    secondary={user.email}
                                                />
                                            </ListItem>
                                        )
                                        // (<ListItem button key={user.id} onClick={() => {this.handleMoveUser(user)}}>
                                        //     <div className="row">
                                        //         <div className="col-11">
                                        //             <ListItemText primary={user.name + " - " + user.email} />
                                        //         </div>
                                        //         <div className="col-1">
                                        //             <ChevronRight style={{marginTop: 5}} />
                                        //         </div>
                                        //     </div>
                                        // </ListItem>)
                                    )
                                    }
                                </List>
                            </Paper>
                        </div>
                        {/* <div className="col-2"></div> */}
                        <div className="col-6">
                            <Paper elevation={3} style={{width: "100%", padding: 10}}>
                                <div className="row">
                                    <div className="col-9">
                                        <h4>User Lotus</h4>
                                    </div>
                                    <div className="col-2 text-right">
                                        <div style={{marginBottom: 10}} className="badge badge-primary">{this.state.countLavengerUser} User</div>
                                    </div>
                                </div>
                                {/* <Divider sx={{marginTop: "5px", marginBottom: "5px"}} /> */}
                                <TextField 
                                    id="txt-keyword-lotus" 
                                    label="pencarian user Lotus" 
                                    variant="outlined" 
                                    type='text'
                                    onChange={this.handleKeywordLotusChanged}
                                    value={this.state.keywordLotusUser}
                                    fullWidth
                                    size='small'
                                />
                                <List style={{maxHeight: 470, overflowY: "scroll", marginTop: "10px"}}>
                                    {
                                    // this.state.listUsers.filter(user => user.isLavengerUser === true).map(user => 
                                    this.filterUserLotus(this.state.keywordLotusUser).map(user => 
                                        (
                                            <ListItem dense key={user.id} sx={{cursor: "pointer"}} onClick={() => {this.handleMoveUser(user)}} >
                                                <ListItemAvatar>
                                                    <ChevronLeft />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={user.name}
                                                    secondary={user.email}
                                                />
                                            </ListItem>
                                        )
                                    //     (<ListItem button key={user.id} onClick={() => {this.handleMoveUser(user)}}>
                                    //     <div className="row">
                                    //         <div className="col-1 text-left">
                                    //             <ChevronLeft style={{marginTop: 5}} />
                                    //         </div>
                                    //         <div className="col-11">
                                    //             <ListItemText primary={user.name + " - " + user.email} />
                                    //         </div>
                                    //     </div>
                                    // </ListItem>)
                                    )
                                    }
                                    
                                </List>
                            </Paper>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}
 
export default EditAppProfile;