import { TextField, Autocomplete, Snackbar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip as ToolTip, Dialog, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import "./ContactsDashboard.css";
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";

import { makeStyles } from '@mui/styles';
import {Add, Search, QuestionAnswer, LeakRemoveTwoTone, Edit, CloudDownload, History} from '@mui/icons-material';
import AddContact from './AddContact';
import ContactEditChangeSales from './ContactEditChangeSales';
import ExportConversation from './ExportConversation';
import HistoryContactLabel from '../HistoryContactLabel';
import moment from 'moment';


class ContactsDashboard extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        listMycustomer: [],
        snackbarVisible: false,
        snackbarMessage: "",
        enableButtonAlihkan: false,
        page: 0,
        rowsPerPage: 10,
        showAddForm: false,
        keyword: "",
        appProfileId: "",
        searchInField: "fromName"
    }

    useStyles1 = makeStyles((theme) => (
            {
                root: {
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
                },
            }
        )
    );

    getSalesTeleUsers = () => {
        axios
        .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({listSales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    handleEditOnClick = (contactData) => {
        const myCustomers = [...this.state.listMycustomer];

        var findCustomer = myCustomers.filter(customer => customer.id === contactData.id);

        if(findCustomer.length > 0){
            findCustomer[0].isEditing = !findCustomer[0].isEditing;
            findCustomer[0].assignToUser = contactData.assignToUser;
            findCustomer[0].assignToUserName = contactData.assignToUserName;
        }

        this.setState({listMycustomer: myCustomers});

    }

    getMyCustomerToday = (replaceAll) => {

        var userData = Global.get();
        const params = {
            userId: userData.user.id,
            page: this.state.page,
            rowsPerPage: this.state.rowsPerPage,
            keywords: this.state.keyword,
            appProfileId: Global.getAppProfile()._id,
            searchInField: this.state.searchInField
        }

        console.log("PARAMS", params, "replaceAll", replaceAll);

        axios
        // .post(Api.getMyCustomerToday(), {userId: userData.user.id}, Api.getRequestHeader())
        .post(Api.contactListPagedSearchable(), params, Api.getRequestHeader())
        .then((response) => {
            console.log("MyCustomerToday : ", response.data.data);

            if(replaceAll === true){

                var customers = response.data.data;
                customers.forEach(customer => {
                    let newData =  {
                        photo: "https://picsum.photos/seed/picsum/200",
                        fromName: `${customer.fromName}`,
                        text: customer.lastMessage,
                        fromNumber: customer.from,
                        contactId: customer.id,
                        toNumber: customer.to,
                        toName: customer.toName,
                        selected: true,
                        unreadCounter: customer.unreadCounter,
                        assignToUserName: customer.assignToUserName
                      };
                    
                    // this.props.onClose();
                    customer.action = 
                    <>
                        {
                            Global.get().admin === true ? 
                            (
                                <ToolTip key={"tooltipedit"+customer.contactId} title="Ubah sales">
                                    <Edit  key={"edit"+customer.contactId} style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditOnClick(customer);}}  />
                                </ToolTip>
                            ) : 
                            (<></>)
                        }
                        &nbsp;
                        <ToolTip key={"tooltipopenchat"+customer.contactId} title="Buka Chat">
                            <QuestionAnswer key={"openchat"+customer.contactId} style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.props.onSelect(newData);}}  />
                        </ToolTip>
                        &nbsp;
                        {
                            Global.get().admin === true ? 
                            (
                                <ToolTip key={"tooltilabel"+customer.contactId} title="History Label">
                                    <History key={"export"+customer.contactId} contactData={newData} style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.setState({showHistoryContactLabel: true, selectedContactNumber: customer.from, selectedContactName: customer.fromName})}}  />
                                </ToolTip>
                            ) : 
                            (<></>)
                        }
                        {
                            // Global.get().admin === true ? 
                            // (
                            //     // <ToolTip key={"tooltipexport"+customer.contactId} title="Download percakapan">
                            //         <CloudDownload key={"export"+customer.contactId} contactData={newData} style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditOnClick(customer);}}  />
                            //     // </ToolTip>
                            // ) : 
                            // (<></>)
                        }
                    </>;
                    customer.isEditing = false;
                });

                this.setState({listMycustomer: customers});
            }else{
                let myCustomers = [...this.state.listMycustomer];

                response.data.data.forEach(customer => {
                    // console.log(element);
                    let newData =  {
                        photo: "https://picsum.photos/seed/picsum/200",
                        fromName: `${customer.fromName}`,
                        text: customer.lastMessage,
                        fromNumber: customer.from,
                        contactId: customer.id,
                        toNumber: customer.to,
                        toName: customer.toName,
                        selected: true,
                        unreadCounter: customer.unreadCounter,
                        assignToUserName: customer.assignToUserName
                      };
                    
                    // this.props.onClose();
                    // customer.action = <div onClick={() => {this.props.onSelect(newData);/*this.props.onClose();*/}} className="btn btn-primary"><QuestionAnswer /> <small>Buka Chat</small></div>;
                    customer.action = 
                    <>
                        {
                            Global.get().admin === true ? 
                            (
                                <ToolTip key={"tooltipedit"+customer.contactId} title="Ubah sales">
                                    <Edit key={"edit"+customer.contactId} style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditOnClick(customer);}}  />
                                </ToolTip>
                            ) : 
                            (<></>)
                        }
                        &nbsp;
                        <ToolTip key={"tooltipopen"+customer.contactId} title="Buka Chat">
                            <QuestionAnswer key={"openchat"+customer.contactId} style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.props.onSelect(newData);}}  />
                        </ToolTip>
                        &nbsp;
                        {
                            Global.get().admin === true ? 
                            (
                                <ToolTip key={"tooltilabel"+customer.contactId} title="History Label">
                                    <History key={"export"+customer.contactId} contactData={newData} style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.setState({showHistoryContactLabel: true, selectedContactNumber: customer.from, selectedContactName: customer.fromName})}}  />
                                </ToolTip>
                            ) : 
                            (<></>)
                        }
                        {
                            // Global.get().admin === true ? 
                            // (
                            //     // <ToolTip key={"tooltipexport"+customer.contactId} title="Download percakapan">
                            //         <ExportConversation key={"export"+customer.contactId} contactData={newData} style={{cursor: "pointer"}} className="text-primary"  />
                            //     // </ToolTip>
                            // ) : 
                            // (<></>)
                        }
                    </>;

                    customer.isEditing = false;
                    myCustomers.push(customer);
                });

                this.setState({listMycustomer: myCustomers});
            }

            
        })
        .catch((error) => {

            

            console.error("There was an error!", userData.user.id, error);
        });
    };

    handleSelectionChanged = (salesName) => {
        const salesData = [...this.state.listSales];
        let selected = salesData.filter((sales) => sales.name===salesName);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));

            this.setState({selectedSales: newData},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            });            
            
        }else{
            this.setState({selectedSales: {id: 0, name: ""}},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            }); 
        }

    }

    handleShowSnackbar = (message) => {
        this.setState({snackbarVisible: true, snackbarMessage: message});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, snackbarMessage: ""});
    }

    getFieldClassName = (fieldName) => {
        let className = "badge";
        if(this.state.searchInField === fieldName){
          className += " badge-primary";
        }else{
          className += " badge-secondary";
        }
    
        return className;
    }
    
    handleFieldSelectionChanged = (fieldName) => {
        this.setState({searchInField: fieldName}, () => {
          this.getMyCustomerToday(true);
        });
    }

    componentDidMount() {
        // this.setState({appProfileId: Global.getAppProfile()._id},()=>{
            // console.log(Global.get());
            this.getMyCustomerToday();
            this.getSalesTeleUsers();
        // });
    }

    columns = [
        { id: 'fromName', label: 'Nama', minWidth: 150 },
        { id: 'from', label: 'No WA', minWidth: 100 },
        // {
        //     id: 'population',
        //     label: 'Population',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //     id: 'size',
        //     label: 'Size\u00a0(km\u00b2)',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        {
            id: 'assignToUserName',
            label: 'Salesman',
            minWidth: 170,
            align: 'left',
        },
        { id: 'created_at', label: 'Tanggal Lead', minWidth: 100 },
        {
            id: 'action',
            label: 'Action',
            minWidth: 100,
            align: 'center',
        },
    ];

    handleChangePage = (event, newPage) => {
        console.log("NEWPAGE : ", newPage);

        var isNavigateToNewPage = newPage >= this.state.listMycustomer.length/this.state.rowsPerPage;

        this.setState({page: newPage}, () => {if(isNavigateToNewPage){this.getMyCustomerToday();}});
    };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({page: 0, rowsPerPage: event.target.value});
    };

    handleCloseFormAddContact = () => {
        this.setState({showAddForm: false});
    }

    handleKeyword = (event) => {
        this.setState({ keyword: event.target.value });
    };

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
          this.getMyCustomerToday(true);
        }
      };

    render() { 
        return ( 
            <div className="container-fluid" style={{paddingTop: "10px"}}>
                <div className="row">
                    <div className="col-10"><h1>{this.state.showAddForm ? "Tambah Kontak" : "Daftar Kontak Anda"}</h1></div>
                    <div className="col-2 text-center">
                        <div className="btn btn-danger" onClick={this.state.showAddForm ? this.handleCloseFormAddContact : this.props.onClose}>
                            {
                                this.state.showAddForm ? "Batalkan" : "X"
                            }
                        </div>
                    </div>
                </div>
                
                {

                    this.state.showAddForm ? 
                    (
                        <AddContact onCloseForm={() => {this.setState({showAddForm: false}, () => {this.getMyCustomerToday()})}} />
                    ) : 
                    
                    (
                        <div className="container-fluid">
                            <div className="row"  style={{marginTop: 10, marginBottom: 10}}>
                                <div className="col-10">
                                    <TextField
                                        onChange={this.handleKeyword}
                                        label="Ketik untuk mencari nama atau nomor wa"
                                        type="text"
                                        className="form-control"
                                        id="namacustomer"
                                        variant="outlined"
                                        onKeyDown={this.onKeyDownHandler}
                                        onBlur={() => {
                                            
                                        }}
                                        size='small'
                                    />
                                </div>
                                <div className="col-2">
                                    <div onClick={() => {this.getMyCustomerToday(true)}} className="btn btn-primary" style={{padding: 6}}><Search /></div>&nbsp;
                                    {
                                        Global.get().admin === true || Global.isTeleAdmin() === true ? (
                                            <div onClick={() => {this.setState({showAddForm: true})}} className="btn btn-primary" style={{padding: 6}}><Add /></div>
                                        ) : (<></>)
                                    }
                                    
                                </div>
                            </div>
                            <div className="container-fluid">
                                <small>Field Pencariana : </small>
                                {/* <div> */}
                                    <div className={this.getFieldClassName("fromName")} onClick={() => this.handleFieldSelectionChanged("fromName")} style={{cursor: "pointer"}}>Nama</div>&nbsp;
                                    <div className={this.getFieldClassName("from")} onClick={() => this.handleFieldSelectionChanged("from")} style={{cursor: "pointer"}}>Nomor</div>&nbsp;
                                    <div className={this.getFieldClassName("assignToUserName")} onClick={() => this.handleFieldSelectionChanged("assignToUserName")} style={{cursor: "pointer"}}>Sales</div>
                                {/* </div> */}
                            </div>
                            <Paper className="root">
                                <TableContainer style={{maxHeight: 440}}>
                                    <Table stickyHeader aria-label="sticky table" size="small">
                                    <TableHead>
                                        <TableRow>
                                        {this.columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                            {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.listMycustomer.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={row.id}>
                                            {this.columns.map((column) => {
                                                // const value = Global.getAppProfile().appVendor === "VONAGE" && Global.isSales() && (column.id === "from" | column.id==="fromName") ? row[column.id].slice(0, -5) + Array(5 + 1).join("x") : row[column.id];
                                                const value = column.id === "from" ? Global.maskPhoneNumber(row[column.id]) : row[column.id];
                                                
                                                var isEditing = row.isEditing;
                                                // return 

                                                    {
                                                        return isEditing === true && column.id === "assignToUserName" ? 
                                                        (
                                                            <TableCell key={column.id} align={column.align}>
                                                                <ContactEditChangeSales onFinishEdit={this.handleEditOnClick} salesData={this.state.listSales} contactData={row} />
                                                            </TableCell>
                                                        ) : 
                                                        (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {
                                                                    column.id === "created_at" ? Global.formatDateTimeToStrDateTime24(value) : column.format && typeof value === 'number' ? column.format(value) : value}        
                                                                {/* {value}         */}
                                                            </TableCell>
                                                        )
                                                    }

                                                
                                                
                                            })}
                                            </TableRow>
                                        );
                                        })}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                    component="div"
                                    count={this.state.listMycustomer.length + this.state.rowsPerPage}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </Paper>
                        </div>
                    )

                }

                {
                    this.state.showHistoryContactLabel === true && this.state.selectedContactNumber !== null ? (
                        <Dialog
                            open={this.state.currentConversation !== null}
                            // TransitionComponent={Transition}
                            // keepMounted
                            fullWidth={true}
                            maxWidth="xl"
                            onClose={() => {this.setState({showHistoryContactLabel: false, selectedContactNumber: null, selectedContactName: null})}}
                            aria-describedby=""
                        >
                            {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                            <DialogContent>
                                <HistoryContactLabel contactNumber={this.state.selectedContactNumber} contactName={this.state.selectedContactName} style={{width: 500}} />
                            </DialogContent>
                            <DialogActions>
                            {/* <Button onClick={handleClose}>Disagree</Button>
                            <Button onClick={handleClose}>Agree</Button> */}
                            </DialogActions>
                        </Dialog>
                    ) : (<></>)
                }
                
                
            </div>
        );
    }
}
 
export default ContactsDashboard;