import { TextField, FormControl, InputLabel, MenuItem, Select, Snackbar,Autocomplete } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import "./Handover.css";
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import ReactLoading from 'react-loading';
import Modal from "react-bootstrap/Modal";
import {CheckCircle} from "@mui/icons-material";


class Handover extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        listMycustomerToday: [],
        snackbarVisible: false,
        snackbarMessage: "",
        enableButtonAlihkan: false,
        isInProgress: false,
        showSetLabelDialog: false,
        listSalesHandover: [],
        tmpLabel: "",
        listCity: []
    }

    getSalesTeleUsers = () => {
        axios
        .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({listSales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    getSalesChatUser = () => {
        axios
        .post(Api.getSalesChatList(), {appProfileId: Global.getAppProfile()._id}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({listSalesHandover: [...response.data.data]});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    getMyCustomerToday = () => {

        var userData = Global.get();

        axios
        // .post(Api.getMyCustomerToday(), {userId: userData.user.id}, Api.getRequestHeader())
        // .post(Api.contactList(), {userId: userData.user.id, appProfileId: Global.getAppProfile()._id}, Api.getRequestHeader())
        .post(Api.getHandoverLists(), {userId: userData.user.id, appProfileId: Global.getAppProfile()._id}, Api.getRequestHeader())
        .then((response) => {
            console.log("HANDOVERLIST : ", response.data.data);
            this.setState({listMycustomerToday: response.data.data});
            // this.setState({listSales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", userData.user.id, error);
        });
    };

    handleSelectionChanged = (salesName) => {
        const salesData = [...this.state.listSales];
        let selected = salesData.filter((sales) => sales.name===salesName);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));

            this.setState({selectedSales: newData},()=>{
                this.handleEnableButtonAlihkan();
                console.log("SELECTEDSALES", this.state.selectedSales);
            });            
            
        }else{
            this.setState({selectedSales: {id: 0, name: ""}},()=>{
                this.handleEnableButtonAlihkan();
                console.log("SELECTEDSALES", this.state.selectedSales);
            }); 
        }

    }

    handleEnableButtonAlihkan = () => {
        const cloneCustomerToday = [...this.state.listMycustomerToday];
        const cloneListSalesHandover = [...this.state.listSalesHandover];

        let filledHandoverTo = cloneCustomerToday.filter((custToday) => custToday.handoverTo !== undefined && custToday.handoverTo > 0);
        let filledSelectedBadge = cloneListSalesHandover.filter((sales) => sales.selected === true);
        
        // if(this.state.selectedSales.id > 0 | filledHandoverTo.length > 0){
        if(filledSelectedBadge.length > 0 | filledHandoverTo.length > 0){
            console.log("ENABLE_BUTTON_ALIHKAN", true);
            this.setState({enableButtonAlihkan: true});
        }else{
            console.log("ENABLE_BUTTON_ALIHKAN", false);
            this.setState({enableButtonAlihkan: false});
        }

    }
    
    handleHandoverSelectionManualChanged = (salesName, contactId) => {
        const cloneCustomerToday = [...this.state.listMycustomerToday];
        const salesData = [...this.state.listSales];
        let selected = salesData.filter((sales) => sales.name===salesName);
        console.log("CONTACTID : ", contactId);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));
            console.log("MANUAL : ",newData);
            // this.setState({selectedSales: newData},()=>{
            //     console.log("SELECTEDSALES", this.state.selectedSales);
            // });            

            for(let element of cloneCustomerToday){
                // console.log("FORLOOPITEM : ", element);
                if(element.id === contactId){
                    element.handoverTo = newData.id;
                    element.handoverToName = newData.name;
                    break;
                }
            }

            console.log("CLONEDDATA : ", cloneCustomerToday);

            // cloneCustomerToday.forEach(element => {
            //     if(element.id === contactId){
            //         element.handoverTo = newData.id;
            //         element.handoverToName = newData.name;
            //     }
            // });

        }else{
            for(let element of cloneCustomerToday){
                // console.log("FORLOOPITEM : ", element);
                if(element.id === contactId){
                    element.handoverTo = 0;
                    element.handoverToName = "";
                    break;
                }
            }
            // this.setState({selectedSales: {id: 0, name: ""}},()=>{
            //     console.log("SELECTEDSALES", this.state.selectedSales);
            // }); 
        }

        this.handleEnableButtonAlihkan();

    }

    handleBulkUpdate = () => {
        const updateContacts = [];
        const cloneSelected = [...this.state.listMycustomerToday];

        let result = cloneSelected.filter((contact) => contact.label !== "PAID" && contact.label !== "UNPAID" && contact.label !== "CANCEL" && contact.label !== "PENDING" );

        if(result.length > 0){
            console.log("HANDOVER", result);
            this.handleShowSnackbar("Masih ada "+(result.length)+" pelanggan yang belum diberi label");
            return;
        }

        // let result = cloneSelected.filter((contact) => contact.label !== "PAID" && contact.label !== "UNPAID" && contact.label !== "CANCEL");

        // if(result.length > 0){
        //     console.log("HANDOVER", result);
        //     this.handleShowSnackbar("Masih ada "+(result.length)+" pelanggan yang belum diberi label");
        //     return;
        // }


        cloneSelected.forEach(contact => {
            
            if(contact.handoverTo !== undefined && contact.handoverTo > 0){
                updateContacts.push(
                    {
                        contactId: contact.id, 
                        userId: contact.assignToUser, 
                        userName: contact.assignToUserName,
                        handoverTo: contact.handoverTo, 
                        handoverToName: contact.handoverToName
                    }
                )
            }else{
                if(this.state.selectedSales.id !== undefined && this.state.selectedSales.id > 0){
                    updateContacts.push(
                        {
                            contactId: contact.id, 
                            userId: this.state.selectedSales.id, 
                            userName: this.state.selectedSales.name,
                            handoverTo: this.state.selectedSales.id, 
                            handoverToName: this.state.selectedSales.name
                        }
                    );
                }
                
            }
            
        });

        console.log("BULKVALUES : ", updateContacts);
        // return;

        // for(const contact in this.state.contactSelectedToUpdate){
        //     console.log("CONTACT : ", contact);
        //     updateContacts.push({contactId: contact.contactId, userId: contact.id, userName: contact.name});
        // }

        this.setState({isInProgress: true}, () => {
            axios
            .post(Api.bulkAssignContactToUser(), {contacts : updateContacts}, Api.getRequestHeader())
            .then((response) => {
                console.log("BULKRESPONSE : ", response.data);
                this.setState({isInProgress: false});
                this.getMyCustomerToday();
                // this.setState({sales: response.data.data});
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
            
        });

        
        console.log("BULK : ", updateContacts);
    }

    handleBulkUpdateNew = () => {
        const updateContacts = [];
        const selectedBadges = [];
        const cloneSelected = [...this.state.listMycustomerToday];
        const cloneListHandoverSales = [...this.state.listSalesHandover];

        let result = cloneSelected.filter((contact) => contact.label !== Global.labelPaid() && contact.label !== Global.labelUnpaid() && contact.label !== Global.labelCancel() && contact.label !== Global.labelDp() && contact.label !== Global.labelPending() && contact.label !== Global.labelClosing() && contact.label !== Global.labelKonfirmasi());
        let resultSales = cloneListHandoverSales.filter((sales) => sales.selected === true);

        if(result.length > 0){
            console.log("HANDOVER", result);
            this.handleShowSnackbar("Masih ada "+(result.length)+" pelanggan yang belum diberi label");
            return;
        }

        cloneSelected.forEach(contact => {
            if(contact.handoverTo === undefined || contact.handoverTo <= 0){
                updateContacts.push(
                    {
                        contactId: contact.id, 
                        userId: contact.assignToUser, 
                        userName: contact.assignToUserName,
                        handoverTo: contact.handoverTo, 
                        handoverToName: contact.handoverToName
                    }
                )
            }else{
                if(this.state.selectedSales.id !== undefined && this.state.selectedSales.id > 0){
                    updateContacts.push(
                        {
                            contactId: contact.id, 
                            userId: this.state.selectedSales.id, 
                            userName: this.state.selectedSales.name,
                            handoverTo: this.state.selectedSales.id, 
                            handoverToName: this.state.selectedSales.name
                        }
                    );
                }
                
            }
            
        });

        resultSales.forEach((sales) => {
            if(sales.selected === true){
                selectedBadges.push(
                    {
                        salesId: sales.lavenderSalesId,
                        salesName: sales.lavenderSalesName
                    }
                );
            }
        });

        // console.log("BULKVALUES : ", updateContacts);
        // return;

        // for(const contact in this.state.contactSelectedToUpdate){
        //     console.log("CONTACT : ", contact);
        //     updateContacts.push({contactId: contact.contactId, userId: contact.id, userName: contact.name});
        // }

        var params = {
            contacts : updateContacts,
            salesNextShift: selectedBadges,
            userId: Global.get().user.id
        };

        console.log("PARAM_HANDOVER", params);
        // return;

        this.setState({isInProgress: true}, () => {
            axios
            .post(Api.bulkAssignContactToUserAuto(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("BULKRESPONSE : ", response.data);
                this.setState({isInProgress: false}, () => {
                    this.getMyCustomerToday();
                });
                // this.setState({sales: response.data.data});
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
            
        });

        
        console.log("BULK : ", updateContacts);
    }

    getCityList = (message) =>{
        let params = {
        };
        // console.log("CITYLIST", JSON.stringify(message));
        axios
          .post(Api.getCityList(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CITYLIST",response.data);
            this.setState({listCity: response.data.data});
    
    
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message });
            console.error("There was an error!", error);
          });
      }
    
      handleCityOnChange = (contact, newCity) => {
        let selected = this.state.listCity.filter((city) => city.name===newCity);
    
        if(selected.length > 0){

            const myCustomers = [...this.state.listMycustomerToday];
            let result = myCustomers.filter((cust) => cust.id===contact.id); 

            if(result.length > 0){
                result[0].cityName = selected[0].name;
                result[0].cityId = selected[0].id;
            }

            this.setState({listMycustomerToday: myCustomers});

        //   this.setState({selectedCity: selected[0].name, selectedCityId: selected[0].id}, () => {
        //     console.log("SELECTED CITY", selected[0]);
        //   });
        }
      }
      
      handleSetErrorMessage = (contact, errorMessage) => {
        const myCustomers = [...this.state.listMycustomerToday];
        let result = myCustomers.filter((cust) => cust.id===contact.id); 

        if(result.length > 0){
            result[0].errorMessage = errorMessage;
        }else{
            return;
        }

        this.setState({listMycustomerToday: myCustomers});

        // this.setState({selectedCity: selected[0].name, selectedCityId: selected[0].id}, () => {
        // console.log("SELECTED CITY", selected[0]);
        // });
      }

    handleShowSnackbar = (message) => {
        this.setState({snackbarVisible: true, snackbarMessage: message});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, snackbarMessage: ""});
    }

    handleShowSetLabelDialog = () => {
        this.setState({ showSetLabelDialog: true });
    };
    
    handleCloseSetLabelDialog = () => {
        this.setState({ showSetLabelDialog: false });
    };

    editLabel = (contact) => {

        const myCustomers = [...this.state.listMycustomerToday];
        let result = myCustomers.filter((cust) => cust.id===contact.id);

        if(result.length > 0){
            result[0].isEditLabel = true;
        }

        this.setState({listMycustomerToday: myCustomers});

    }

    handleSetContactLabel = (contact, labelValue) => {

        let params = {
          contactNumber: contact.from,
          labelValue: labelValue
        };

        if(Global.isSalesDataPending() === true){
            params.isDataPending = true;
        }

        var labelsUseCategory = [
            Global.labelPaid(),
            Global.labelUnpaid(),
            Global.labelDp(),
            Global.labelPending(),
            Global.labelCancel(),
        ];

        if(labelsUseCategory.includes(labelValue)){
            params.productCategory = contact.productCategory;
            params.cityName = contact.cityName;
            params.cityId = contact.cityId;
        }

        if(labelValue === Global.labelRandomLeads()){
            
            if(contact.keterangan !== undefined && contact.keterangan !== null && contact.keterangan.length > 0){
                params.keterangan = contact.keterangan;
            }else{
                this.handleSetErrorMessage(contact, "Keterangan belum diisi");
                return;
            }

        }

        if(labelsUseCategory.includes(labelValue) && contact.productCategory.length <= 0){
            this.handleSetErrorMessage(contact, "Kategori produk belum dipilih");
            return;
        }
    
        if(labelsUseCategory.includes(labelValue) && contact.cityId.length <= 0){
            this.handleSetErrorMessage(contact, "Kota belum dipilih");
            return;
        }
        
        if(labelValue === Global.labelPending() || labelValue === Global.labelUnpaid()){
            if(contact.deliveryDate === "" || contact.deliveryDate === null || contact.deliveryDate === undefined){
              this.handleSetErrorMessage(contact, "Tanggal Pengiriman harus diisi");
              return;
            }else{
                this.handleSetErrorMessage(contact, "");
            }
        }

        params.deliveryDate = contact.deliveryDate;

        console.log("PARAMLABEL",params);
        // return;
        
        axios
          .post(Api.setContactLabel(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("SETCONTACTLABEL : ", response.data);

            let responseData = response.data.data;

            if(response.data.success === true){
                const myCustomers = [...this.state.listMycustomerToday];
                let result = myCustomers.filter((cust) => cust.id===contact.id);

                if(response.data.data.isTeleCustomer != true && response.data.data.label === Global.labelPaid() || response.data.data.label === Global.labelCancel() || response.data.data.label == Global.labelRandomLeads()){//remove this contact
                    console.log("REMOVECONTACT");
                    let index = myCustomers.findIndex((element) => element.id === contact.id );
                    if(index >= 0){
                        myCustomers.splice(index, 1);
                    }
                }else{//update contact state
                    if(result.length > 0){
                        console.log("UPDATECONTACT");
                        result[0].label = responseData.label;
                        result[0].isEditLabel = false;
                        result.updated_at = responseData.updated_at;
                    }
                }

                console.log("NEW_LISTHANDOVER",myCustomers);
                
                this.setState({listMycustomerToday: myCustomers}, () => {
                    this.props.onUpdateConversations(false);
                });
            }

            this.handleShowSnackbar(response.data.message);

          })
          .catch((error) => {
            this.setState({ errorMessage: error.message });
            console.error("There was an error!", error);
          });
      }

    handleClickBadgeSales = (sales) => {
        const salesHandover = [...this.state.listSalesHandover];
        let filteredSales = salesHandover.filter((item) => item.lavenderSalesId===sales.lavenderSalesId);

        if(filteredSales.length > 0){
            let selected = false;

            if(sales.selected !== undefined){
                selected = sales.selected === true ? false : true;
            }else{
                selected = true;
            }

            filteredSales[0].selected = selected;
        }

        this.setState({listSalesHandover: salesHandover}, ()=> {
            this.handleEnableButtonAlihkan();
        });

    }

    getBadgeSalesStyle = (sales) => {
        let style = "badge badge-pill ";
        if(sales.selected === true){
            style += "badge-primary";
        }else{
            style += "badge-secondary";
        }

        return style;
    }

    handleShowProductCategory = (contact, labelValue, showCategory) => {
        const myCustomers = [...this.state.listMycustomerToday];
        let result = myCustomers.filter((cust) => cust.id===contact.id); 

        if(result.length > 0){
            // if(result[0].showProductCategory === true){
            if(showCategory === false){
                result[0].showProductCategory = false;
                result[0].productCategory = "";
                result[0].tmpLabel = "";

            }else{
                result[0].showProductCategory = true;
                result[0].tmpLabel = labelValue;
            }
        }

        this.setState({listMycustomerToday: myCustomers});

    }

    handleCancelCategoryChanged = (event, contact) => {
        console.log("event", event.target.value);
        console.log("contact", contact);
        const myCustomers = [...this.state.listMycustomerToday];
        let result = myCustomers.filter((cust) => cust.id===contact.id); 

        if(result.length > 0){
            result[0].productCategory = event.target.value;
        }

        this.setState({listMycustomerToday: myCustomers});
    }

    handleTanggalPengirimanChange = (event, contact) => {
        console.log("DATE_ORI", event.target.value);

        const myCustomers = [...this.state.listMycustomerToday];
        let result = myCustomers.filter((cust) => cust.id===contact.id); 

        if(result.length > 0){
            var formatDeliveryDate = event.target.value.replace("/", "-");
            console.log(formatDeliveryDate);
            result[0].deliveryDate = event.target.value;
        }

        
        this.setState({ listMycustomerToday: myCustomers});
        
      }
    
      handleKeteranganChange = (event, contact) => {
        const myCustomers = [...this.state.listMycustomerToday];
        let result = myCustomers.filter((cust) => cust.id===contact.id); 

        if(result.length > 0){
            result[0].keterangan = event.target.value;
        }

        
        this.setState({ listMycustomerToday: myCustomers});
        
      }

    componentDidMount() {
        console.log(Global.get());
        this.getMyCustomerToday();
        this.getSalesTeleUsers();
        this.getSalesChatUser();
        this.getCityList();
    }
    

    render() { 
        return ( 
            <div className="handover-container">
                <div className="row">
                    <div className="col-11"><h1>Pengalihan Pelanggan</h1></div>
                    <div className="col-1">
                        <div className="btn btn-danger" onClick={this.props.onClose}>
                            X
                        </div>
                    </div>
                </div>
                
                Alihkan semua pelanggan saya kepada 
                <div className="row"  style={{marginTop: 10, marginBottom: 10}}>
                    <div className="col-9">
                        {/* <Autocomplete
                            id="free-solo-2-demo"
                            // disableClearable
                            freeSolo 
                            options={this.state.listSales.map((option) => {
                                // console.log("LABEL : ", option);
                                return String(option.name);
                            })}
                            onChange={(event, newValue) => {
                                console.log("NEWVALUE : ", event);
                                this.handleSelectionChanged(newValue);
                            }} 
                            value={""}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Sales"
                                margin="0"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                            )}
                        /> */}
                        <div class="card">
                            <div class="card-body">
                            {
                                    this.state.listSalesHandover.map(
                                        (sales) => {
                                            return sales.salesType === "Acquisition" ? 
                                                (<><div style={{cursor: "pointer"}} onClick={() => this.handleClickBadgeSales(sales)} className={this.getBadgeSalesStyle(sales)}>{sales.lavenderSalesName}</div>&nbsp;</>) : 
                                                (<></>)
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    
                    {
                        this.state.isInProgress ? 
                        (
                            <div className="col-3">
                                <div className="alert alert-danger">
                                    <div className="row">
                                        <div className="col-2">
                                            <ReactLoading color="#FF0000" width="30px" height="30px" type="spin"/>
                                        </div>
                                        <div className="col-10">
                                            sedang mengalihkan ...
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : 
                        (
                            <div className="col-3">
                                {
                                    this.state.listMycustomerToday.length > 0 && this.state.enableButtonAlihkan > 0 ? 
                                    <div onClick={this.handleBulkUpdateNew} className="btn btn-primary btn-block" style={{padding: 15}}>Alihkan</div> :
                                    <div onClick={() => this.handleShowSnackbar("Belum dapat mengalihkan, karena sales belum dipilih atau hari ini anda belum mendapat pelanggan")} className="btn btn-secondary btn-block" style={{padding: 15}}>Alihkan</div> 
                                }
                                
                            </div>
                        )
                    }
                    
                    
                </div>
                                
                <div className="row">
                    <div className="col-9"><h4>Pelanggan saya hari ini</h4></div>
                    <div className="col-3">
                        {/* <div className="btn btn-info btn-block" onClick={() => {var win = window.open("https://lavender.prestisa.id/#!/enquiries/create", '_blank');win.focus();}}>
                            Create Enquiries
                        </div> */}
                    </div>
                </div>
                <br />
                <table class="table">
                    <thead>
                        <tr>
                            <th width="40%" scope="col">Nama Pelanggan</th>
                            <th width="20%" scope="col">Label</th>
                            <th width="20%" scope="col">Nomor WhatsApp</th>
                            {/* <th width="20%" scope="col">Alihkan Kepada</th> */}
                        </tr>
                    </thead>
                    <tbody>
                {
                    this.state.listMycustomerToday.map(
                        (contact) => (
                            <tr>
                                <td>{Global.getAppProfile().appVendor === "VONAGE" && Global.isSales() ? contact.fromName.slice(0, -5) + Array(5 + 1).join("x") : contact.fromName}</td>
                                <td>

                                    {
                                        contact.label !== undefined && contact.label !== null && contact.label.length > 0 ? 
                                        (
                                            <div className={Global.getLabelClasses(contact.label)}>{contact.label}</div>
                                            // <>
                                            //                 <div>
                                            //                     <span onClick={() => this.handleSetContactLabel(contact, Global.labelPaid())}  className={Global.getLabelClasses(Global.labelPaid())} style={{cursor: "pointer"}}>
                                            //                     Label Paid
                                            //                     </span>
                                            //                 </div>
                                            //                 <div>
                                            //                     <span onClick={() => this.handleSetContactLabel(contact, Global.labelUnpaid())} className={Global.getLabelClasses(Global.labelUnpaid())} style={{cursor: "pointer"}}>
                                            //                     Label Unpaid
                                            //                     </span>
                                            //                 </div>
                                            //                 <div>
                                            //                     <span onClick={() => this.handleSetContactLabel(contact, Global.labelDp())} className={Global.getLabelClasses(Global.labelDp())} style={{cursor: "pointer"}}>
                                            //                     Label DP
                                            //                     </span>
                                            //                 </div>
                                            //             </>
                                        ) : 
                                        contact.isEditLabel === true ? 
                                        (
                                            <>
                                                {
                                                    contact.showProductCategory === true ? 
                                                    (
                                                        <>
                                                            <div>
                                                                {/* <span onClick={() => this.handleSetContactLabel(contact, Global.labelPaid())}  className={Global.getLabelClasses(Global.labelPaid())} style={{cursor: "pointer"}}> */}
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelPaid(), true)}  className={Global.getLabelClasses(Global.labelPaid())} style={{cursor: "pointer"}}>
                                                                    Label Paid
                                                                </span>
                                                                {
                                                                    contact.tmpLabel == Global.labelPaid() ? (<CheckCircle className='text-info' />) : (<></>) 
                                                                }
                                                            </div>
                                                            <div>
                                                                {/* <span onClick={() => this.handleSetContactLabel(contact, Global.labelUnpaid())} className={Global.getLabelClasses(Global.labelUnpaid())} style={{cursor: "pointer"}}> */}
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelUnpaid(), true)} className={Global.getLabelClasses(Global.labelUnpaid())} style={{cursor: "pointer"}}>
                                                                    Label Unpaid
                                                                </span>
                                                                {
                                                                    contact.tmpLabel == Global.labelUnpaid() ? (<CheckCircle className='text-info' />) : (<></>) 
                                                                }
                                                            </div>
                                                            <div>
                                                                {/* <span onClick={() => this.handleSetContactLabel(contact, Global.labelDp())} className={Global.getLabelClasses(Global.labelDp())} style={{cursor: "pointer"}}> */}
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelDp(), true)} className={Global.getLabelClasses(Global.labelDp())} style={{cursor: "pointer"}}>
                                                                    Label DP
                                                                </span>
                                                                {
                                                                    contact.tmpLabel == Global.labelDp() ? (<CheckCircle className='text-info' />) : (<></>) 
                                                                }
                                                            </div>
                                                            <div>
                                                                {/* <span onClick={() => this.handleSetContactLabel(contact, Global.labelDp())} className={Global.getLabelClasses(Global.labelDp())} style={{cursor: "pointer"}}> */}
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelPending(), true)} className={Global.getLabelClasses(Global.labelPending())} style={{cursor: "pointer"}}>
                                                                    Label Pending
                                                                </span>
                                                                {
                                                                    contact.tmpLabel == Global.labelPending() ? (<CheckCircle className='text-info' />) : (<></>) 
                                                                }
                                                            </div>
                                                            <div>
                                                                {/* <span onClick={() => this.handleSetContactLabel(contact, Global.labelDp())} className={Global.getLabelClasses(Global.labelDp())} style={{cursor: "pointer"}}> */}
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelRandomLeads(), true)} className={Global.getLabelClasses(Global.labelRandomLeads())} style={{cursor: "pointer"}}>
                                                                    Label Random Leads
                                                                </span>
                                                                {
                                                                    contact.tmpLabel == Global.labelRandomLeads() ? (<CheckCircle className='text-info' />) : (<></>) 
                                                                }
                                                            </div>
                                                            <div>
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelCancel(), true)} className={Global.getLabelClasses(Global.labelCancel())} style={{cursor: "pointer"}}>
                                                                    Label Cancel
                                                                </span>
                                                                {
                                                                    contact.tmpLabel == Global.labelCancel() ? (<CheckCircle className='text-info' />) : (<></>) 
                                                                }
                                                            </div>
                                                        </>
                                                    ) : 
                                                    (
                                                        <>
                                                            <div>
                                                                {/* <span onClick={() => this.handleSetContactLabel(contact, Global.labelPaid())}  className={Global.getLabelClasses(Global.labelPaid())} style={{cursor: "pointer"}}> */}
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelPaid(), true)}  className={Global.getLabelClasses(Global.labelPaid())} style={{cursor: "pointer"}}>
                                                                Label Paid
                                                                </span>
                                                            </div>
                                                            <div>
                                                                {/* <span onClick={() => this.handleSetContactLabel(contact, Global.labelUnpaid())} className={Global.getLabelClasses(Global.labelUnpaid())} style={{cursor: "pointer"}}> */}
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelUnpaid(), true)} className={Global.getLabelClasses(Global.labelUnpaid())} style={{cursor: "pointer"}}>
                                                                Label Unpaid
                                                                </span>
                                                            </div>
                                                            <div>
                                                                {/* <span onClick={() => this.handleSetContactLabel(contact, Global.labelDp())} className={Global.getLabelClasses(Global.labelDp())} style={{cursor: "pointer"}}> */}
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelDp(), true)} className={Global.getLabelClasses(Global.labelDp())} style={{cursor: "pointer"}}>
                                                                Label DP
                                                                </span>
                                                            </div>
                                                            <div>
                                                                {/* <span onClick={() => this.handleSetContactLabel(contact, Global.labelDp())} className={Global.getLabelClasses(Global.labelDp())} style={{cursor: "pointer"}}> */}
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelPending(), true)} className={Global.getLabelClasses(Global.labelPending())} style={{cursor: "pointer"}}>
                                                                Label Pending
                                                                </span>
                                                            </div>
                                                            <div>
                                                                {/* <span onClick={() => this.handleSetContactLabel(contact, Global.labelDp())} className={Global.getLabelClasses(Global.labelDp())} style={{cursor: "pointer"}}> */}
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelRandomLeads(), true)} className={Global.getLabelClasses(Global.labelRandomLeads())} style={{cursor: "pointer"}}>
                                                                    Label Random Leads
                                                                </span>
                                                            </div>
                                                            <div>
                                                                {/* <span onClick={() => this.handleSetContactLabel(contact, Global.labelDp())} className={Global.getLabelClasses(Global.labelDp())} style={{cursor: "pointer"}}> */}
                                                                <span onClick={() => this.handleShowProductCategory(contact, Global.labelCancel(), true)} className={Global.getLabelClasses(Global.labelCancel())} style={{cursor: "pointer"}}>
                                                                Label Cancel
                                                                </span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                <div>
                                                    {/* <span onClick={() => this.handleSetContactLabel(contact, "CANCEL")} className="badge badge-pill badge-danger" style={{cursor: "pointer"}}> */}
                                                    {/* <span onClick={() => this.handleShowProductCategory(contact, Global.labelCancel())} className="badge badge-pill badge-danger" style={{cursor: "pointer"}}>
                                                    Label Cancel
                                                    </span> */}

                                                    {
                                                        contact.showProductCategory === true ? 
                                                        (
                                                            <>
                                                                {
                                                                    contact.tmpLabel !== Global.labelRandomLeads() ? (
                                                                        <>
                                                                            <FormControl variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                                                                                <InputLabel id="demo-simple-select-outlined-label">Kategori Produk</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-outlined-label"
                                                                                    id="demo-simple-select-outlined"
                                                                                    value={contact.productCategory}
                                                                                    onChange={event => this.handleCancelCategoryChanged(event,contact)}
                                                                                    label="Kategori Produk"
                                                                                >
                                                                                    {
                                                                                    Global.getListProductCategories().map(
                                                                                        (cat) => {
                                                                                        return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                                                                        } 
                                                                                    )
                                                                                    }
                                                                                </Select>
                                                                            </FormControl>
                                                                            <br />
                                                                            <br />
                                                                            <Autocomplete
                                                                                id="free-solo-2-demo"
                                                                                // disableClearable
                                                                                freeSolo 
                                                                                options={this.state.listCity.map((option) => {
                                                                                    // console.log("LABEL : ", option);
                                                                                    return String(option.name);
                                                                                })}
                                                                                onChange={(event, newValue) => {
                                                                                    // console.log("NEWVALUE : ", event);
                                                                                    this.handleCityOnChange(contact, newValue);
                                                                                }} 
                                                                                value={contact.cityName}
                                                                                renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Pilih Kota"
                                                                                    margin="0"
                                                                                    variant="outlined"
                                                                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                                                                />
                                                                                )}
                                                                            />
                                                                            <br />
                                                                            {
                                                                                contact.tmpLabel === Global.labelPending() || contact.tmpLabel === Global.labelUnpaid() ? 
                                                                                (
                                                                                    <TextField
                                                                                        id="dateStart"
                                                                                        label="Tanggal Pengiriman"
                                                                                        type="datetime-local"
                                                                                        value={contact.deliveryDate}
                                                                                        // variant="outline"
                                                                                        defaultValue={contact.deliveryDate}
                                                                                        onChange={(event) => this.handleTanggalPengirimanChange(event, contact)}
                                                                                        // className={classes.textField}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                        }}
                                                                                    />
                                                                                ) : (<></>)
                                                                            }
                                                                        </>
                                                                    ) : (<></>)
                                                                }
                                                                {/* <br /> */}
                                                                {
                                                                    contact.tmpLabel === Global.labelRandomLeads() ? 
                                                                    (
                                                                        <>
                                                                            <br />
                                                                            <TextField
                                                                                id="keterangan"
                                                                                label="Keterangan"
                                                                                value={contact.keterangan}
                                                                                variant="outlined"
                                                                                defaultValue={contact.keterangan}
                                                                                onChange={(event) => this.handleKeteranganChange(event, contact)}
                                                                                // className={classes.textField}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                multiline
                                                                                fullWidth
                                                                            />
                                                                        </>
                                                                    ) : (<></>)
                                                                }
                                                                <div className="row">
                                                                    <div className="col-12 text-center text-danger">
                                                                        {contact.errorMessage}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{marginTop: "10px"}}>
                                                                    <div className="col-6">
                                                                        {/* { */}
                                                                            {/* contact.productCategory !== undefined && contact.productCategory !== null && contact.productCategory.length > 0 && contact.productCategory !== undefined && contact.cityId !== null && contact.cityId > 0 ? 
                                                                            ( */}
                                                                                <button className="btn btn-primary btn-block btn-sm" onClick={() => this.handleSetContactLabel(contact, contact.tmpLabel)}>Simpan</button>
                                                                            {/* ) :  */}
                                                                            {/* (
                                                                                <button className="btn btn-primary btn-block btn-sm" disabled>Simpan</button>
                                                                            ) */}
                                                                        {/* } */}
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="btn btn-danger btn-block btn-sm" onClick={() => this.handleShowProductCategory(contact, contact.tmpLabel, false)}>Batal</div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : 
                                                        (
                                                            <></>
                                                        )
                                                        
                                                    }

                                                </div>
                                            </>
                                        ) : 
                                        (
                                            <div className="text-danger" onClick={() => this.editLabel(contact)} style={{textDecoration: "underline", cursor: "pointer"}}>Set Label</div>
                                        )
                                    }

                                    
                                </td>
                                {/* <td>{Global.getAppProfile().appVendor === "VONAGE" && Global.isSales() ? contact.from.slice(0, -5) + Array(5 + 1).join("x") : contact.from}</td> */}
                                <td>{Global.maskPhoneNumber(contact.from)}</td>
                                
                                {/* <td>
                                {
                                        contact.label !== undefined && contact.label !== null && contact.label.length > 0 ? 
                                        (
                                    <Autocomplete
                                        id= {"free-solo-2-demo-" + contact.id}
                                        key= {"free-solo-2-demo-" + contact.id}
                                        // disableClearable
                                        freeSolo 
                                        options={this.state.listSales.map((option) => {
                                            // console.log("LABEL : ", option);
                                            return String(option.name);
                                        })}
                                        onChange={(event, newValue) => {
                                            console.log("NEWVALUE : ", event);
                                            this.handleHandoverSelectionManualChanged(newValue, contact.id);
                                        }} 
                                        value={""}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Sales"
                                            margin="0"
                                            variant="outlined"
                                            InputProps={{ ...params.InputProps, type: 'search' }}
                                        />
                                        )}
                                    />
                                    ) : 
                                        (<></>)
                                    }
                                </td> */}
                            </tr>
                            // <div className="row">
                            //     <div className="col-8">{contact.fromName}</div>
                            //     <div className="col-4">{contact.from}</div>
                            // </div>
                        ) 
                    )
                }
                    </tbody>
                </table>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbarVisible}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />

<Modal
                show={this.state.showSetLabelDialog}
                onHide={this.handleCloseSetLabelDialog}
                backdrop="static"
                keyboard={false}
            >
            <Modal.Header closeButton><strong>Label Pelanggan</strong></Modal.Header>
            <Modal.Body>
                <center>
                    Tentukan label untuk pelanggan<br />
                    <strong>
                        {/* {this.props.currentConversation.fromName}<br />
                        {this.props.currentConversation.fromNumber} */}
                    </strong>
                </center>
            </Modal.Body>
            <Modal.Footer>
                {/* <div className="row">
                <div className="col-4">
                    <span onClick={() => this.handleSetContactLabel(this.props.currentConversation, "PAID")} className="btn btn-success btn-block">
                    Label Paid
                    </span>
                </div>
                <div className="col-4">
                    <span onClick={() => this.handleSetContactLabel(this.props.currentConversation, "UNPAID")} className="btn btn-primary btn-block">
                    Label Unpaid
                    </span>
                </div>
                <div className="col-4">
                    <span onClick={() => this.handleSetContactLabel(this.props.currentConversation, "CANCEL")} className="btn btn-danger btn-block">
                    Label Cancel
                    </span>
                </div>
                </div> */}
                
                {/* <span
                onClick={this.handleCloseSetLabelDialog}
                className="btn btn-warning"
                >
                Batalkan
                </span> */}
            </Modal.Footer>
            </Modal>

            </div>
        );
    }
}
 
export default Handover;