import React, { Component } from 'react';
import axios from "axios";
import Api from "../../../customlib/Api";
import moment from "moment";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, TableFooter } from '@mui/material';
import Global from "../../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography,LinearProgress} from '@mui/material';

import 'moment-duration-format';
import { Refresh, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Check, Close, QuestionAnswer, TrainRounded, Analytics } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageListViewer from '../../MessageListViewer';
import DetailClosingAndAvgResponseBySales from './DetailClosingAndAvgResponseBySales';

class ClosingAndAvgResponse extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        isLoading: true,
        avgAll: 0, 
        appProfileId: "",
        dateRangeValue: [null,null],
        currentConversation: null,
        resumeByKategori: [],
        years: [],
        selectedMonth: moment().format("M"),
        selectedYear: moment().format("YYYY"),
        dataPerforma: [],
        contactLeadsHourly: [],
        listAppProfiles: [],
        filterAppNumber: "",
        performaTotalCancel: 0,
        performaTotalPengurangLeads: 0,
        performaTotalLeads: 0,
        summaryWeekly: {},
        performaSalesByLabel: [],
        weeklyLeadsPercentage: [],
        summary : {
          leads: 0,
          closing: 0,
          persenClosing: 0,
          avgResponseTime: "00:00",
          avgClosingTime: "00:00"
        },
        dialogOpen: false,
        selectedSales: null,
        summaryCRSuccessPayment: [],
        summaryCRPendingPayment: [],
        summaryAvgCRAcv: [],
        lavenderOrder: []
    }
    
    componentDidMount() {
      let arrYears = [];
      for(let i=2020;i<=moment().format("YYYY");i++){
        arrYears.push(i);
      }
      this.setState(
        {appProfileId: Global.getAppProfile()._id, filterAppNumber: Global.getAppProfile().waNumber, years: arrYears}, ()=>{
          this.handleRefresh();
          // this.getResumeWeekly(true);
        }
      );
    }

    handleRefresh = () => {
        this.loadAppProfile();
        this.lavenderOrderByCustomer();
        // this.getContactLeads();
    }

    handleChangeMonth = (event) => {
      this.setState({selectedMonth: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleChangeYear = (event) => {
      this.setState({selectedYear: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleAppTypeChanged = (event) => {
      this.setState({appProfileId: event.target.value}, ()=>{
        console.log(this.state.appProfileId);
        // this.getContactLeads();
        this.handleRefresh();
      });
    }

    loadAppProfile = () => {
      const params = {
      };
      
      axios
        .post(
          Api.listAppProfile(),
          params,
          Api.getRequestHeaderNoAuth()
        )
        .then((response) => {
          console.log("App Profiles", response.data);
          
          let profiles = [];
          profiles.push({_id: "", waNumber: "", label: "---"});

          let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
          profilesFiltered.forEach(item => {
              profiles.push(item);
          });
          this.setState({listAppProfiles: profiles});
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, loginSuccess: false });
          console.error("There was an error!", error);
          // this.props.onLogin();
        }
      );
  };

  lavenderOrderByCustomer = () => {
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };
    
    axios
      .post(
        Api.getLavenderOrderBySales(),
        params,
        Api.getRequestHeader()
      )
      .then((response) => {
        console.log("LAVENDER ORDER", response.data);
        this.setState({lavenderOrder: response.data.data}, () => {
          this.getContactLeads();
        });
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message, loginSuccess: false });
        console.error("There was an error!", error);
        // this.props.onLogin();
      }
    );
  };

  getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            appProfileId: this.state.appProfileId
        }

        this.setState({contactLeads: [], contactLeadsHourly: [], performaTotalCancel: 0, performaTotalLeads: 0, performaTotalPengurangLeads: 0, additionalData: null, isLoading: true}, () => {
            axios
            .post(Api.getContactLeads(), params, Api.getRequestHeader())
            .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            var tmpContactLeads = response.data.data;
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }
            
            var performaSalesByLabel = [];
            var total = {
              leads: 0,
              closing: 0,
              persenClosing: 0,
              totalOrder: 0,
              totalQty: 0,
              totalLessOneMinute: 0,
              totalMoreOneMinute: 0,
              avgResponseTime: 0,
              avgClosingTime: 0
            };

            var summaryCRSuccessPayment = [];
            var summaryCRPendingPayment = [];
            var summaryAvgCRAcv = [];

            let lavenderOrderData = [...this.state.lavenderOrder];
            tmpContactLeads.forEach(lead => {

                let leadsExcludeLabelRandom = lead.leads.filter(itemLead => itemLead.label !== Global.labelRandomLeads());

                let jumlahLeads = leadsExcludeLabelRandom.length;
                // let totalClosing = lead.leads.filter(itemLead => itemLead.label === Global.labelUnpaid() || itemLead.label === Global.labelPaid() || itemLead.label === Global.labelDp()).length;
                let totalClosing = leadsExcludeLabelRandom.filter(itemLead => itemLead.isClosing).length;
                let persenClosing = totalClosing > 0 ? (totalClosing/jumlahLeads*100).toFixed(1) : 0;
                let avgResponseTime = 0;
                let avgClosingTime = 0;

                total.leads += jumlahLeads;
                total.closing += totalClosing;

                var performaSalesByLabelItem = {
                    salesName: lead.salesName,
                    jumlahLeads: jumlahLeads,
                    totalClosing: totalClosing,
                    persenClosing: persenClosing,
                    totalOrder: 0,
                    totalQty: 0,
                    totalLessOneMinute: 0,
                    totalMoreOneMinute: 0,
                    avgResponseTime: "00:00",
                    avgClosingTime: "00:00"
                };

                let penjualan = lavenderOrderData.find(order => order.owner === lead.salesId);
                if(penjualan){
                  performaSalesByLabelItem.totalQty = penjualan.qty_items;
                  performaSalesByLabelItem.totalOrder = penjualan.total;
                  // totalOrder += penjualan.totalOrder;
                }else{
                  performaSalesByLabelItem.totalQty = 0;
                  performaSalesByLabelItem.totalOrder = 0;
                }

                performaSalesByLabel.push(performaSalesByLabelItem);

                let totalResponseTime = 0;
                let totalClosingTime = 0;
                // let totalOrder = 0;
                leadsExcludeLabelRandom.forEach(item => {
                  var delta = 0;
                  if(item.closingDeltaTime !== undefined && item.closingDeltaTime !== null){
                    totalClosingTime += item.closingDeltaTime;
                  }
                  if(item.avgResponseTime != null){
                    // var firstMessageTime = moment(item.createdAt).tz("asia/jakarta"); //todays date
                    // var firstResponseTime = moment(item.firstResponse).tz("asia/jakarta"); // another date
                    
                    // var duration = moment.duration(firstResponseTime.diff(firstMessageTime));
                    delta = item.avgResponseTime;
                    totalResponseTime += delta < 0 ? 0 : delta;
                  }else{
                    delta = 24 * 3600;
                  }
                  item.responseTime = delta;
                  if(delta <= 60){
                    performaSalesByLabelItem.totalLessOneMinute += 1;
                  }else{
                    performaSalesByLabelItem.totalMoreOneMinute += 1;
                  }

                  // let penjualan = lavenderOrderData.find(order => order.phone === item.from);
                  // if(penjualan){
                  //   performaSalesByLabelItem.totalQty += penjualan.qty_items;
                  //   performaSalesByLabelItem.totalOrder += penjualan.total;
                  //   // totalOrder += penjualan.totalOrder;
                  // }else{
                  //   performaSalesByLabelItem.totalQty += 0;
                  //   performaSalesByLabelItem.totalOrder += 0;
                  // }
                });

                avgResponseTime = totalResponseTime > 0 ? totalResponseTime/jumlahLeads : 0;
                performaSalesByLabelItem.avgResponseTime = Global.formatTime(Math.round(avgResponseTime));
                total.avgResponseTime += avgResponseTime;
                
                // avgClosingTime = totalClosingTime > 0 ? totalClosingTime/jumlahLeads : 0;
                avgClosingTime = totalClosingTime > 0 ? totalClosingTime/totalClosing : 0;
                performaSalesByLabelItem.avgClosingTime = Global.formatTime(Math.round(avgClosingTime));
                total.avgClosingTime += avgClosingTime;

                total.totalLessOneMinute += performaSalesByLabelItem.totalLessOneMinute;
                total.totalMoreOneMinute += performaSalesByLabelItem.totalMoreOneMinute;
                total.totalOrder += performaSalesByLabelItem.totalOrder;
                total.totalQty += performaSalesByLabelItem.totalQty;

                // let leadsClosing = lead.leads.filter(itemLead => [Global.labelPaid(), Global.labelUnpaid(), Global.labelDp()].includes(itemLead.label));
                let leadsClosing = lead.leads.filter(itemLead => itemLead.isClosing);
                //CR Success Payment
                let leadsPaid = lead.leads.filter(itemLead => [Global.labelPaid(), Global.labelDp()].includes(itemLead.label));
                summaryCRSuccessPayment.push({
                  salesName: lead.salesName,
                  leadsCount: leadsClosing.length,
                  closing: leadsPaid.length,
                  persenClosing: leadsClosing.length > 0 ? (leadsPaid.length/leadsClosing.length*100).toFixed(1) : 0
                });

                //CR Pending Payment
                let leadsUnpaid = lead.leads.filter(itemLead => itemLead.label === Global.labelUnpaid());
                summaryCRPendingPayment.push({
                  salesName: lead.salesName,
                  leadsCount: leadsClosing.length,
                  closing: leadsUnpaid.length,
                  persenClosing: leadsClosing.length > 0 ? (leadsUnpaid.length/leadsClosing.length*100).toFixed(1) : 0
                });

                //Total % ACV CR Sales
                // let leadsPaidAcv = lead.leads.filter(itemLead => itemLead.label === Global.labelPaid());
                // let leadsPaidAcv = lead.leads.filter(itemLead => [Global.labelPaid(), Global.labelDp(), Global.labelUnpaid()].includes(itemLead.label));
                let leadsPaidAcv = lead.leads.filter(itemLead => itemLead.isClosing);
                let leadsByDate = [];
                leadsPaidAcv.forEach(itemPaid => {
                  let leadsDate = Global.formatDateTimeToStrDate(itemPaid.created_at);
                  let findLeadsByDate = leadsByDate.find(byDate => byDate.leadsDate === leadsDate);
                  let leadsPerDate = leadsPaidAcv.filter(perDate => Global.formatDateTimeToStrDate(perDate.created_at) === leadsDate);
                  if(findLeadsByDate){
                    if([Global.labelPaid(), Global.labelDp()].includes(itemPaid.label)){
                      findLeadsByDate.totalPaid += 1;
                    }
                    findLeadsByDate.totalLeads += 1;
                    findLeadsByDate.avgCr = leadsPerDate.length > 0 ? findLeadsByDate.totalPaid/leadsPerDate.length*100 : 0;
                  }else{
                    leadsByDate.push({
                      leadsDate: leadsDate,
                      totalPaid: [Global.labelPaid(), Global.labelDp()].includes(itemPaid.label) ? 1 : 0,
                      totalLeads: 1,
                      avgCr: leadsByDate.length > 0 ? [Global.labelPaid(), Global.labelDp()].includes(itemPaid.label) ? 1 : 0/leadsPerDate.length*100 : 0
                    });
                  }

                });

                summaryAvgCRAcv.push({
                  salesName: lead.salesName,
                  leadsCount: lead.leads.length,
                  lessThan55: 0,
                  moreThan55: 0,
                  avgByDate: leadsByDate
                });

            });

            console.log("LOG___SUMMARY",summaryAvgCRAcv);

            summaryCRSuccessPayment.forEach(item => {
              let findSales = summaryCRPendingPayment.find(pending => pending.salesName === item.salesName);
              if(findSales){
                item.closingUnpaid = findSales.closing;
                item.persenClosingUnpaid = findSales.persenClosing;
              }
            });

            summaryAvgCRAcv.forEach(summ => {
              summ.avgByDate.forEach(byDate => {
                summ.lessThan55 += byDate.avgCr <= 55 ? 1 : 0;
                summ.moreThan55 += byDate.avgCr > 55 ? 1 : 0;
              });
            });
            

            total.persenClosing = (total.closing/total.leads*100).toFixed(2);
            total.avgResponseTime = Global.formatTime(Math.round(total.avgResponseTime/performaSalesByLabel.length));
            // total.avgClosingTime = Global.formatTime(Math.round(total.avgClosingTime/performaSalesByLabel.length));
            total.avgClosingTime = Global.formatTime(Math.round(total.avgClosingTime/total.closing));

            this.setState({
              contactLeads: tmpContactLeads, 
              performaSalesByLabel: performaSalesByLabel, 
              summary: total, 
              isLoading: false, 
              additionalData: response.data.additional_data.length > 0 ? response.data.additional_data[0] : null,
              summaryCRSuccessPayment: summaryCRSuccessPayment,
              summaryCRPendingPayment: summaryCRPendingPayment,
              summaryAvgCRAcv: summaryAvgCRAcv
            }, () => {
                // console.log("CONTACTLEADS : ", this.state.contactLeads);
            });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      });
      
    };

    convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");
     
      let [hours, minutes, seconds] = time.split(":");
     
      if (hours === "12") {
        hours = "00";
      }
     
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
     
      return `${hours}:${minutes}:${seconds}`;
    };

    handleShowMessages = (conversation) => {
      // console.log("CONVER", conversation);
      this.setState({currentConversation: conversation});
    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
    };

    formatTime = (seconds) => {
      var duration = moment.duration(seconds, 'seconds');
      var formatted = duration.format("hh:mm:ss");
      return seconds < 60 ? formatted : "00:"+formatted;
    }

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, () => this.handleRefresh());
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    handleSelectSales = (salesName) => {
      console.log("CONTACTLEADS", this.state.contactLeads);
      let selected = this.state.contactLeads.find((item) => item.salesName === salesName);
      console.log("SELECTED", selected);
      if(selected){
        this.setState({selectedSales: selected}, this.toggleShowDialogDetail);
      }

    }

    toggleShowDialogDetail = () => {

      if(this.state.dialogOpen){
        this.setState({dialogOpen: !this.state.dialogOpen, selectedSales: null});
      }else{
        this.setState({dialogOpen: !this.state.dialogOpen});
      }

      
    }

    render() { 
        
        return ( 
            <Paper style={{padding:20, backgroundColor: "#e6e9ed"}}>

              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                          <div className="col-6"></div>
                          <div className="col-2" style={{padding: "5px"}}>
                            <MyDateRangePicker onDateChanged={this.handleDateChanged} />
                          </div>
                          <div className="col-2">
                              <FormControl variant="outlined" style={{width: '100%'}} size='small'>
                                  <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={this.state.appProfileId}
                                      onChange={this.handleAppTypeChanged}
                                      label="Aplikasi"
                                  >
                                      {
                                      this.state.listAppProfiles.map(
                                          (profile) => {
                                          return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                                          } 
                                      )
                                      }
                                  </Select>
                              </FormControl>
                            </div>
                          <div className="col-2 text-center" style={{borderRadius: 10, padding: 0}}>
                              
                              {
                                this.state.isLoading ? 
                                (
                                  <>
                                    Sedang memuat
                                    <LinearProgress color="secondary" />
                                  </>
                                ) : 
                                (
                                  <div className="btn btn-primary" onClick={this.handleRefresh}>
                                    <Refresh /> Refresh
                                  </div>
                                )
                              }
                              
                            </div>
                        </div>

                        {/* Performa sales by label */}
                        <Divider style={{marginTop: "10px", marginBottom: "10px"}} />
                        <div className="row align-items-center">
                            <div className="col-6">
                              <h4>Closing & Average Response Time</h4>
                            </div>
                            <div className="col-2"></div>
                            <div className="col-1"></div>
                            <div className="col-2 text-center" style={{borderRadius: 10, padding: 5}}></div>
                        </div>
                        
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                          <TableCell key={"head0"} align={"left"} width={10}>
                                              
                                          </TableCell>
                                          <TableCell key={"head1"} align={"left"}>
                                              <strong>Sales</strong>
                                          </TableCell>
                                          <TableCell key={"head2"} align={"center"}>
                                              <strong>Jumlah Leads</strong>
                                          </TableCell>
                                          <TableCell key={"head3"} align={"center"}>
                                              <strong>Closing</strong>
                                          </TableCell>
                                          <TableCell key={"head4"} align={"center"}>
                                              <strong>% Closing</strong>
                                          </TableCell>
                                          <TableCell key={"head6"} align={"center"}>
                                              <strong>Sales Value</strong>
                                          </TableCell>
                                          <TableCell key={"head7"} align={"center"}>
                                              <strong>Sales Qty</strong>
                                          </TableCell>
                                          <TableCell key={"head8"} align={"center"}>
                                              <strong>&lt; 1 Minute</strong>
                                          </TableCell>
                                          <TableCell key={"head9"} align={"center"}>
                                              <strong>&gt; 1 minute</strong>
                                          </TableCell>
                                          <TableCell key={"head10"} align={"center"}>
                                              <strong>Avg Response Time</strong>
                                          </TableCell>
                                          <TableCell key={"head11"} align={"center"}>
                                              <strong>Avg Time Closing</strong>
                                          </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.performaSalesByLabel.map((row) => {
                                        return (
                                            <TableRow>
                                              <TableCell width={10} align='center'><Analytics onClick={() => this.handleSelectSales(row.salesName)} color='primary' style={{cursor: "pointer"}} /></TableCell>
                                              <TableCell>{row.salesName}</TableCell>
                                              <TableCell align='center'>{row.jumlahLeads}</TableCell>
                                              <TableCell align='center'>{row.totalClosing}</TableCell>
                                              <TableCell align='center'>{row.persenClosing}</TableCell>
                                              <TableCell align='center'>{new Intl.NumberFormat("en-id").format(row.totalOrder)}</TableCell>
                                              <TableCell align='center'>{row.totalQty}</TableCell>
                                              <TableCell align='center'>{row.totalLessOneMinute}</TableCell>
                                              <TableCell align='center'>{row.totalMoreOneMinute}</TableCell>
                                              <TableCell align='center'>{row.avgResponseTime}</TableCell>
                                              <TableCell align='center'>{row.avgClosingTime}</TableCell>
                                            </TableRow>
                                        );
                                        })}
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell><strong>Summary</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.leads}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.closing}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.persenClosing}</strong></TableCell>
                                            <TableCell align='center'><strong>{new Intl.NumberFormat("en-id").format(this.state.summary.totalOrder)}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.totalQty}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.totalLessOneMinute}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.totalMoreOneMinute}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.avgResponseTime}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.avgClosingTime}</strong></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        {/* Performa sales by label */}
                        
                        <div className="row align-items-center" style={{marginTop: "20px"}}>
                            <div className="col-12">
                              <h4>Closing Based On Payment Status</h4>
                            </div>
                        </div>
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                          <TableCell key={"head1"} align={"left"}>
                                              <strong>Sales</strong>
                                          </TableCell>
                                          <TableCell key={"head2"} align={"center"}>
                                              <strong>Customer Closing</strong>
                                          </TableCell>
                                          <TableCell key={"head3"} align={"center"}>
                                              <strong>Customer Paid</strong>
                                          </TableCell>
                                          <TableCell key={"head4"} align={"center"}>
                                              <strong>% Closing Paid</strong>
                                          </TableCell>
                                          <TableCell key={"head3"} align={"center"}>
                                              <strong>Customer Unpaid</strong>
                                          </TableCell>
                                          <TableCell key={"head4"} align={"center"}>
                                              <strong>% Closing Unpaid</strong>
                                          </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.summaryCRSuccessPayment.map((row) => {
                                        return (
                                            <TableRow>
                                              <TableCell>{row.salesName}</TableCell>
                                              <TableCell align='center'>{row.leadsCount}</TableCell>
                                              <TableCell align='center'>{row.closing}</TableCell>
                                              <TableCell align='center'>{row.persenClosing}</TableCell>
                                              <TableCell align='center'>{row.closingUnpaid}</TableCell>
                                              <TableCell align='center'>{row.persenClosingUnpaid}</TableCell>
                                            </TableRow>
                                        );
                                        })}
                                        {/* <TableRow>
                                            <TableCell><strong>Summary</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.leads}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.closing}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.persenClosing}</strong></TableCell>
                                        </TableRow> */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        
                        {/* <div className="row align-items-center" style={{marginTop: "20px"}}>
                            <div className="col-12">
                              <h4>CR Success Payment</h4>
                            </div>
                        </div>
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                          <TableCell key={"head1"} align={"left"}>
                                              <strong>Sales</strong>
                                          </TableCell>
                                          <TableCell key={"head2"} align={"center"}>
                                              <strong>Leads</strong>
                                          </TableCell>
                                          <TableCell key={"head3"} align={"center"}>
                                              <strong>Closing</strong>
                                          </TableCell>
                                          <TableCell key={"head4"} align={"center"}>
                                              <strong>% Closing</strong>
                                          </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.summaryCRSuccessPayment.map((row) => {
                                        return (
                                            <TableRow>
                                              <TableCell>{row.salesName}</TableCell>
                                              <TableCell align='center'>{row.leadsCount}</TableCell>
                                              <TableCell align='center'>{row.closing}</TableCell>
                                              <TableCell align='center'>{row.persenClosing}</TableCell>
                                            </TableRow>
                                        );
                                        })}
                                        <TableRow>
                                            <TableCell><strong>Summary</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.leads}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.closing}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.persenClosing}</strong></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <div className="row align-items-center" style={{marginTop: "20px"}}>
                            <div className="col-12">
                              <h4>CR Pending Payment</h4>
                            </div>
                        </div>
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                          <TableCell key={"head1"} align={"left"}>
                                              <strong>Sales</strong>
                                          </TableCell>
                                          <TableCell key={"head2"} align={"center"}>
                                              <strong>Leads</strong>
                                          </TableCell>
                                          <TableCell key={"head3"} align={"center"}>
                                              <strong>Closing</strong>
                                          </TableCell>
                                          <TableCell key={"head4"} align={"center"}>
                                              <strong>% Closing</strong>
                                          </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.summaryCRPendingPayment.map((row) => {
                                        return (
                                            <TableRow>
                                              <TableCell>{row.salesName}</TableCell>
                                              <TableCell align='center'>{row.leadsCount}</TableCell>
                                              <TableCell align='center'>{row.closing}</TableCell>
                                              <TableCell align='center'>{row.persenClosing}</TableCell>
                                            </TableRow>
                                        );
                                        })}
                                        <TableRow>
                                            <TableCell><strong>Summary</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.leads}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.closing}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.persenClosing}</strong></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper> */}

                        <div className="row align-items-center" style={{marginTop: "20px"}}>
                            <div className="col-12">
                              <h4>Total % Acv CR sales</h4>
                            </div>
                        </div>
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                          <TableCell key={"head1"} align={"left"}>
                                              <strong>Sales</strong>
                                          </TableCell>
                                          <TableCell key={"head2"} align={"center"}>
                                              <strong>&lt; 55%</strong>
                                          </TableCell>
                                          <TableCell key={"head3"} align={"center"}>
                                              <strong>&gt; 55%</strong>
                                          </TableCell>
                                          <TableCell key={"head4"} align={"center"}>
                                              <strong>Jumlah Kehadiran</strong>
                                          </TableCell>
                                          <TableCell key={"head4"} align={"center"}>
                                              <strong>% Acv</strong>
                                          </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.summaryAvgCRAcv.map((row) => {
                                          let totalClosing = row.lessThan55 + row.moreThan55;
                                          let persenClosing = totalClosing > 0 ? (row.moreThan55/totalClosing*100).toFixed(1) : 0;
                                        return (
                                            <TableRow>
                                              <TableCell>{row.salesName}</TableCell>
                                              <TableCell align='center'>{row.lessThan55}</TableCell>
                                              <TableCell align='center'>{row.moreThan55}</TableCell>
                                              <TableCell align='center'>{totalClosing}</TableCell>
                                              <TableCell align='center'>{persenClosing}</TableCell>
                                            </TableRow>
                                        );
                                        })}
                                        {/* <TableRow>
                                            <TableCell><strong>Summary</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.leads}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.closing}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.persenClosing}</strong></TableCell>
                                        </TableRow> */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="panel-footer">
                    </div>
                </div>
              </div>

              <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={this.state.dialogOpen}
                onClose={this.toggleShowDialogDetail}
              >
                {/* <DialogTitle>Optional sizes</DialogTitle> */}
                {/* <DialogContent className='bg-secondary'> */}
                  {
                    this.state.selectedSales && <DetailClosingAndAvgResponseBySales leadsData={this.state.selectedSales} onClose={this.toggleShowDialogDetail} />
                  }
                {/* </DialogContent> */}
              </Dialog>

            </Paper>
         );
    }
}
 
export default ClosingAndAvgResponse;

const formatTime = (seconds) => {
  var duration = moment.duration(seconds, 'seconds');
  var formatted = duration.format("hh:mm:ss");
  return seconds < 60 ? "00:"+formatted : formatted;
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});


function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }